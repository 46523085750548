<script>
import userService from '@/helpers/userService';
import errorHandler from '@/helpers/errorHandler';
import moment from 'moment';
/**
 * Topbar component here
 */
export default {
  async created(){
    this.baseUrl =process.env.VUE_APP_BASE_URL;
    await this.$store.dispatch('user/restoreData').then(async ()=>{
      if(this.$store.getters['user/isLoggedIn'])
        await this.$store.dispatch('user/getProfile').then( res =>{
          if(res.student_data){
            this.image = res.student_data.user.image;
          }else{
            this.image = res.image
          }
        })
        if(userService.methods.userRole() == 1){
          await this.$store.dispatch('announcement/getAllYearAnnouncements',userService.methods.userYear()).then( token =>{

          }).catch(err=>{
            if(err){
              console.log('No announcement')
            }
          });
        }
    });
    
  },
  computed:{
    announcements(){
      return this.$store.getters['announcement/allAnnouncements']
    }
  },
  filters:{
    formatDate(value){
    if(value){
        return moment(String(value)).format('hh:mm A DD/MM/YYYY')
    }
    },
  },
  data() {
    return {
      image:null,
      baseUrl: null,
    };
  },
  mounted() {
    
  },
  methods: {
    /**
     * Toggle menu
     */
    showAnnouncement(item) {
      errorHandler.methods.announcement(item.announcement)
    },
    /**
     * Toggle menu
     */
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    /**
     * Full screen
     */
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    /**
     * Toggle rightbar
     */
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    /**
     * Horizontal-toggle menu
     */
    horizonalmenu() {
      let element = document.getElementById("topnav-menu-content");
      element.classList.toggle("show");
    },
    /**
     * Logout User
     */
     async logoutUser(){
      await this.$store.dispatch('user/logoutUser').then(token =>{
        this.$router.push({
          path: '/'
        });
      }).catch(err =>{
        errorHandler.methods.error(err);
      });
      
    },
    /**
     * Load User image
     */
     loadImage(img){
        let path = '';
        try{
          path = `${this.baseUrl}e95c2c2dd3ef780c269f75ee3286df1c418bc016/${'profile_images'}/${img}`
          return path
        }catch(e){
          path = require('@/assets/images/users/avatar-1.jpg')
          return path;
        }
      },
  },
};
</script>

<template>
  <!-- Topbar Start -->
  <div class="navbar-custom">
    <div class="container-fluid">
      <ul class="list-unstyled topnav-menu float-right mb-0">
        <!-- Search -->
        <!-- <li class="dropdown d-inline-block d-lg-none">
          <a
            class="nav-link dropdown-toggle arrow-none waves-effect waves-light"
            data-toggle="dropdown"
            href="#"
            role="button"
            aria-haspopup="false"
            aria-expanded="false"
          >
            <i class="fe-search noti-icon"></i>
          </a>
          <div class="dropdown-menu dropdown-lg dropdown-menu-right p-0">
            <form class="p-3">
              <input
                type="text"
                class="form-control"
                :placeholder="$t('navbar.search.text')"
                aria-label="Recipient's username"
              />
            </form>
          </div>
        </li> -->
        <!-- Full screan button -->
        <li class="dropdown d-none d-lg-inline-block">
          <a
            class="nav-link dropdown-toggle arrow-none waves-effect waves-light"
            @click="initFullScreen"
            data-toggle="fullscreen"
            href="#"
          >
            <i class="fe-maximize noti-icon"></i>
          </a>
        </li>
        <!-- icons -->
        <!-- <b-nav-item-dropdown
          variant="white"
          class="d-none d-lg-inline-block topbar-dropdown"
          toggle-class="nav-link"
          right
          menu-class="dropdown-lg p-0"
        >
          <template v-slot:button-content>
            <i class="fe-grid noti-icon"></i>
          </template>
          <div class="px-lg-2">
            <div class="row no-gutters">
              <div class="col">
                <a class="dropdown-icon-item" href="#">
                  <img src="@/assets/images/brands/github.png" alt="Github" />
                  <span>{{ $t("navbar.dropdown.site.list.github") }}</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="#">
                  <img
                    src="@/assets/images/brands/dribbble.png"
                    alt="dribbble"
                  />
                  <span>{{ $t("navbar.dropdown.site.list.dribbble") }}</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="#">
                  <img src="@/assets/images/brands/slack.png" alt="slack" />
                  <span>{{ $t("navbar.dropdown.site.list.slack") }}</span>
                </a>
              </div>
            </div>

            <div class="row no-gutters">
              <div class="col">
                <a class="dropdown-icon-item" href="#">
                  <img src="@/assets/images/brands/g-suite.png" alt="G Suite" />
                  <span>{{ $t("navbar.dropdown.site.list.g-suite") }}</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="#">
                  <img
                    src="@/assets/images/brands/bitbucket.png"
                    alt="bitbucket"
                  />
                  <span>{{ $t("navbar.dropdown.site.list.bitbucket") }}</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="#">
                  <img src="@/assets/images/brands/dropbox.png" alt="dropbox" />
                  <span>{{ $t("navbar.dropdown.site.list.dropbox") }}</span>
                </a>
              </div>
            </div>
          </div>
        </b-nav-item-dropdown> -->
        <!-- Notifications -->
        <b-nav-item-dropdown
          right
          class="notification-list"
          menu-class="dropdown-lg"
          v-if="$store.getters['user/isLoggedIn'] && $store.getters['user/role'] == 1"
        >
          <template slot="button-content" class="nav-link dropdown-toggle">
            <i class="fe-bell noti-icon"></i>
            <span class="badge badge-danger rounded-circle noti-icon-badge">{{ announcements.length }}</span>
          </template>

          <a href="#" class="dropdown-item noti-title">
            <h5 class="m-0">
              <!-- <span class="float-right">
                <a href class="text-dark">
                  <small>{{
                    $t("navbar.dropdown.notification.subtext")
                  }}</small>
                </a>
              </span> -->
              {{ $t("navbar.dropdown.notification.text") }}
            </h5>
          </a>

          <simplebar style="max-height: 230px">
            <template v-for="item in announcements" >
            <a
              href="#"
              class="dropdown-item notify-item active"
              :key="item.id"
              @click="showAnnouncement(item)"
            >
              <div class="notify-icon bg-soft-primary text-primary">
                <i class="mdi mdi-comment-account-outline"></i>
              </div>
              <p class="notify-details">
                {{ item.announcement }}
                <small class="text-muted">{{
                  item.date_time | formatDate
                }}</small>
              </p>
            </a>
            </template>
          </simplebar>

          <b-button
            to="/announcements"
            class="dropdown-item text-center text-primary notify-item notify-all"
          >
            {{ $t("navbar.dropdown.notification.button") }}
            <i class="fi-arrow-right"></i>
          </b-button>
        </b-nav-item-dropdown>
        <!-- Profile menu -->
        <b-nav-item-dropdown
          right
          class="notification-list topbar-dropdown"
          menu-class="profile-dropdown"
          toggle-class="p-0"
          v-if="$store.getters['user/isLoggedIn']"
        >
          <template slot="button-content" class="nav-link dropdown-toggle">
            <div class="nav-user mr-0">
              <!-- <div v-if="image != 'false' && image"> -->
                <img
                  :src="loadImage(this.image)"
                  alt="user-image"
                  class="rounded-circle"
                />
              <!-- </div> -->
              <!-- <img
                v-else
                :src="require(`@/assets/images/users/avatar-1.jpg`)"
                alt="user-image"
                class="rounded-circle"
              /> -->
              <span class="pro-user-name ml-1 d-inline">
                {{ $store.getters['user/userName'] }}
                <i class="mdi mdi-chevron-down"></i>
              </span>
            </div>
          </template>

          <b-dropdown-header>
            <h6 class="text-overflow m-0 py-2">
              Welcome !
            </h6>
          </b-dropdown-header>

          <b-dropdown-item to="/me">
            <i class="ri-account-circle-line"></i>
            <span>My account</span>
          </b-dropdown-item>


          <!-- <b-dropdown-item to="/redeem-code" v-if="$store.getters['user/role'] == 1">
            <i class="ri-wallet-line"></i>
            <span>
              Balance
            </span>
          </b-dropdown-item> -->


          <b-dropdown-divider></b-dropdown-divider>
          <b-button class="dropdown-item" @click="logoutUser">
            <i class="fe-log-out mr-1"></i>
            <span>{{ $t("navbar.dropdown.name.list.logout") }}</span>
          </b-button>
        </b-nav-item-dropdown>
        <!-- Not logged in user -->
        <li class="dropdown d-none d-lg-inline-block" v-if="!$store.getters['user/isLoggedIn']">
          <a
            class="nav-link dropdown-toggle arrow-none waves-effect waves-light"
            href="/login"
          >
            <i class="fe-user noti-icon mr-1"></i>
            Login
          </a>
        </li>
        <li class="dropdown d-none d-lg-inline-block" v-if="!$store.getters['user/isLoggedIn']">
          <a
            class="nav-link dropdown-toggle arrow-none waves-effect waves-light"
            href="/register"
          >
            <i class="fe-user-plus noti-icon mr-1"></i>
            Sign up
          </a>
        </li>
        <!-- Layout Settings -->
        <!-- <li class="dropdown notification-list">
          <a
            href="javascript:void(0);"
            @click="toggleRightSidebar"
            class="nav-link right-bar-toggle toggle-right"
          >
            <i class="fe-settings noti-icon toggle-right"></i>
          </a>
        </li> -->
      </ul>

      <!-- LOGO -->
      <div class="logo-box">
        <router-link to="/" class="logo logo-dark text-center">
          <span class="logo-sm">
            <img src="@/assets/images/logo-sm-dark.png" alt height="24" />
            <!-- <span class="logo-lg-text-light">Minton</span> -->
          </span>
          <span class="logo-lg">
            <img src="@/assets/images/logo-dark.png" alt height="45" />
            <!-- <span class="logo-lg-text-light">M</span> -->
          </span>
        </router-link>

        <router-link to="/" class="logo logo-light text-center">
          <span class="logo-sm">
            <img src="@/assets/images/logo-sm.png" alt height="24" />
          </span>
          <span class="logo-lg">
            <img src="@/assets/images/logo-light.png" alt height="45" />
          </span>
        </router-link>
      </div>

      <ul class="list-unstyled topnav-menu topnav-menu-left m-0">
        <li>
          <button
            class="button-menu-mobile waves-effect waves-light"
            @click="toggleMenu"
          >
            <i class="fe-menu"></i>
          </button>
        </li>

        <li>
          <!-- Mobile menu toggle (Horizontal Layout)-->
          <a
            class="navbar-toggle nav-link"
            data-toggle="collapse"
            data-target="#topnav-menu-content"
            @click="horizonalmenu()"
          >
            <div class="lines">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </a>
          <!-- End mobile menu toggle-->
        </li>
        <li class="dropdown d-none d-lg-inline-block"  v-if="$store.getters['user/isLoggedIn'] && $route.name === 'landing'">
          <router-link
            class="nav-link dropdown-toggle arrow-none waves-effect waves-light"
            to="/home"
          >
            <i class="fe-home noti-icon">
            </i>
              Dashboard
          </router-link>
        </li>
        <!-- <li class="dropdown d-none d-lg-inline-block"  v-if="!$store.getters['user/isLoggedIn']">
          <a
            class="nav-link dropdown-toggle arrow-none waves-effect waves-light"
            href="#"
          >
            <i class="fe-phone noti-icon">
            </i>
              Contact us
          </a>
        </li> -->
      </ul>
      <div class="clearfix"></div>
    </div>
  </div>
  <!-- end Topbar -->
</template>
