<script>
/**
 * Footer Component
 */
export default {}
</script>

<template>
<!-- Footer Start -->
<footer class="footer">
    <div class="container-fluid">
        <div class="row justify-content-center align-items-end" style="margin-top: 9.5rem;">
            <div class="col-md-4">
                <img src="../assets/images/logo-light.png" alt="Dr.Micheal Labib" style="max-width: 19.25rem;">
            </div>
            <div class="col-md-4 text-right">
                <b-button pill class="ml-2" to="https://web.facebook.com/drmichaellabib">
                    <i class="fab fa-facebook-f" style="font-size: 1.25rem;"></i>
                </b-button>
                <b-button pill class="ml-2" to="https://api.whatsapp.com/send?phone=0201026857398">
                    <i class="fab fa-whatsapp" style="font-size: 1.25rem;"></i>
                </b-button>
                <b-button pill class="ml-2" to="https://www.youtube.com/@dr.michaellabib7994">
                    <i class="fab fa-youtube" style="font-size: 1.25rem;"></i>
                </b-button>
            </div>
        </div>
        <hr style="width: 70%;">
        <!-- <br> -->
        <div class="row justify-content-center">
            <div class="col-md-6 text-center">
                Copyright &copy; 2024 Dr.Micheal Labib
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-md-6 text-center">
                All rights reserved
            </div>
        </div>
    </div>
</footer>
<!-- end Footer -->
</template>

<style scoped>
.footer {
    background-image: url("../assets/images/Landing/footer-bg.png") !important;
    background-repeat: no-repeat;
    background-color: #fff !important;
    background-position: center;
    background-size: 100% 100%;
    height: 450px;
}
</style>
