export const menuItems = [
    {
        id: 1,
        label: "Dashboard",
        icon: "ri-dashboard-line",
        link: '/home'
    },
    {
        id: 3,
        label: "Login",
        icon: "ri-user-line",
        link: '/login'
    },
    {
        id: 4,
        label: "Sign up",
        icon: "ri-user-add-line",
        link: '/register'
    },
];

